import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import backgroundImage from "../images/Bg-1.png";
import Submit from "../images/Submit.svg";

const Improvement = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedRating = parseInt(searchParams.get('rating'));

  const [selectedItems, setSelectedItems] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showImprovements, setShowImprovements] = useState(false);
  const [userLocation, setUserLocation] = useState('');

  const improvementOptions = [
    "Timeliness",
    "Amenities in the car",
    "Cleanliness of the car",
    "Chauffeur",
  ];

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          reverseGeocode(latitude, longitude);
        },
        (error) => {
          console.error("Error obtaining geolocation: ", error);
          setUserLocation("Geolocation not available");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setUserLocation("Geolocation not supported");
    }

    if (selectedRating >= 5) {
      handleSubmit(); // Automatically submit if rating is 5 or more
    } else {
      setShowImprovements(true); // Show improvements if rating is less than 5
    }
  }, [selectedRating]);

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
      
      if (response.data.results.length > 0) {
        const addressComponents = response.data.results[0].address_components;
        const city = addressComponents.find(component => component.types.includes("locality"))?.long_name;
        const area = addressComponents.find(component => component.types.includes("sublocality_level_1"))?.long_name || 
                     addressComponents.find(component => component.types.includes("neighborhood"))?.long_name;

        setUserLocation(`${area}, ${city}`);
      }
    } catch (error) {
      console.error("Error reverse geocoding location: ", error);
      setUserLocation("Geolocation not available");
    }
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(item)
        ? prevItems.filter((i) => i !== item)
        : [...prevItems, item]
    );
  };

  const handleSubmit = async () => {
    console.log("Submitted improvements:", selectedItems);

    if (!selectedRating) {
      alert("No rating found in the URL.");
      return;
    }

    try {
      const url = process.env.REACT_APP_API_URL;

      let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json"
      };

      let bodyContent = JSON.stringify({
        "ratings": selectedRating,
        "improvement": selectedItems.join(", "),
        "driverId": "66f5882dde55ef26a65aa49f",
        "clientId": "66f58846de55ef26a65aa4a1",
        "passengerName": "Default Passenger",
        "tripType": "Transfer",
        "geolocation": userLocation,
        "savedAtTime": new Date().toISOString()
      });

      let response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });

      let data = await response.text();
      console.log(data);

      setIsSubmitted(true);
    } catch (error) {
      console.error("API call or navigation failed:", error);
      alert("Unable to proceed. Please try again later.");
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center p-4 text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        className="absolute inset-0"
        style={{
          background:
            "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 50%, #000000 100%)",
        }}
      ></div>
      <div className="w-full max-w-md flex flex-col items-center relative z-10">
        {!isSubmitted ? (
          <>
            {showImprovements && (
              <>
                <h2 className="font-serif text-4xl italic mb-8 text-center">
                  What we could have improved?
                </h2>
                <div className="w-full space-y-6 flex flex-col items-center mb-16">
                  {improvementOptions.map((option, index) => (
                    <div key={index} className="flex flex-col items-center w-full">
                      <button
                        onClick={() => handleItemClick(option)}
                        className={`py-4 px-4 text-center text-xl transition-all relative w-full h-16 flex items-center justify-center
                          ${selectedItems.includes(option)
                            ? "bg-white text-black"
                            : "text-white hover:text-yellow-200"
                          }`}
                        style={{ fontFamily: "'Montserrat', sans-serif" }}
                      >
                        {index !== 0 && (
                          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-5/6 border-t border-white"></div>
                        )}
                        <span className="block pt-1">{option}</span>
                        {index === improvementOptions.length - 1 && (
                          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-5/6 border-t border-white"></div>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!showImprovements && (
              <h2 className="font-serif text-4xl italic text-center -mt-28">
                Thank you for your feedback!
              </h2>
            )}
          </>
        ) : (
          <h2 className="font-serif text-4xl italic text-center -mt-28">
            Thank you for your feedback!
          </h2>
        )}
      </div>
      {!isSubmitted && (
        <div className="absolute bottom-8 flex justify-center w-full z-20">
          <button onClick={handleSubmit} className="p-2">
            <img src={Submit} alt="Submit feedback" className="w-22 h-16" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Improvement;
