import "./App.css";
import React from "react";
import Feedback from "./components/Feedback";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Improvement from "./components/Improvement";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Passenger Survey - Premio</title>
        <link rel="icon" type="image/png" href="/fav.ico" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/feedback" />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/improvement" element={<Improvement />} />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
