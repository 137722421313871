import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Submit from "../images/Submit.svg";
import Backgrond from "../images/Bg-1.png";
import { useNavigate } from "react-router-dom";

const Feedback = () => {
  const navigate = useNavigate();
  const [selectedRating, setSelectedRating] = useState(null);

  const ratings = [
    { icon: "☹", value: 1 },
    { icon: "🙁", value: 2 },
    { icon: "😐", value: 3 },
    { icon: "🙂", value: 4 },
    { icon: "😊", value: 5 },
  ];

  const handleSubmit = () => {
    if (selectedRating) {
      try {
        navigate(`/improvement?rating=${selectedRating}`);
      } catch (error) {
        console.error("Navigation failed:", error);
        // Fallback behavior, e.g., showing an alert
        alert("Unable to proceed. Please try again later.");
      }
    } else {
      alert("Please select a rating before submitting.");
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center p-4 text-white bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 50%, #000000 100%), url(${Backgrond})`,
      }}
    >
      <div className="w-full max-w-md flex flex-col items-center">
        <h2
          className={`font-bold mb-4 text-center ${isMobile ? "text-3xl" : "text-2xl"
            } font-didot italic`}
        >
          You're on your way!
        </h2>
        <p
          className={`mb-8 text-center ${isMobile ? "text-5xl" : "text-2xl"
            } font-didot italic`}
        >
          How's your experience so far?
        </p>
        <div className="flex justify-between w-full mb-12">
          {ratings.map((rating) => (
            <button
              key={rating.value}
              onClick={() => setSelectedRating(rating.value)}
              className={`${isMobile ? "w-12 h-12" : "w-14 h-14"
                } rounded-full bg-white flex items-center justify-center ${selectedRating === rating.value ? "opacity-100" : "opacity-50"
                } transition-opacity`}
            >
              <span
                className={`text-black ${isMobile ? "text-2xl" : "text-3xl"
                  } font-thin`}
              >
                {rating.icon}
              </span>
            </button>
          ))}
        </div>
        <div id="button-group" className="buttonnext">
          <button
            onClick={handleSubmit}
            className="p-2 bg-white text-black rounded"
          >
            Next
          </button>

        </div>


      </div>
    </div>
  );
};

export default Feedback;
